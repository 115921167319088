import React from "react"
import { Link } from "gatsby"
import { Title, Meta } from "react-head"
import MainLayout from "../components/MainLayout"

const Careers = () => {
  return (
    <MainLayout>
      <Title> Careers | Codetrain Africa - Ghana | Apps coding bootcamp</Title>
      <Meta
        name="description"
        content="Codetrain was born out of the idea that when people fulfill
        their dreams, they are able to positively impact their communities.
        We welcome driven people who want to make a difference. You can 
                  work with us on-site or remotely."
      />
      <div
        className="container"
        style={{ marginTop: "3rem", marginBottom: "4rem" }}
      >
        <div className="row">
          <div className="col-md-10 offset-md-1 col-sm-12 col-xs-12">
            <p>
              Our mission is to empower people to rise digitally by training
              them to become software developers and global leaders; setting
              them up to make impact in their communities.
            </p>
            <div className="career-text">
              <h3>Career opportunities at Codetrain</h3>
              <p>
                We welcome driven people who want to make a difference. You can
                work with us on-site or remotely.
              </p>
              <br />

              <div className="jobs">
                <h5 style={{ color: "#eb4b6a" }}>Teaching fellowship:</h5>
                <p>
                  Are you passionate about technology, teaching and want to make
                  a difference, apply to be a teaching fellow.
                </p>
                <a
                  href="https://drive.google.com/open?id=1vdHGGxvcVQnb26zDfgGQySHr8eU5_ZU4OWpZfWuL1ow"
                  className="btn-custom-secondary-small"
                >
                  Apply
                </a>
                <br /> <br />
                <h5 style={{ color: "#eb4b6a" }}>Mentorship:</h5>
                <p>
                  Are you interested in mentoring Ghana's next top software
                  developers, volunteer to mentor some of our students.
                </p>
                <a
                  href="https://drive.google.com/open?id=1vdHGGxvcVQnb26zDfgGQySHr8eU5_ZU4OWpZfWuL1ow"
                  className="btn-custom-secondary-small"
                >
                  Apply
                </a>
                <br /> <br />
                <h5 style={{ color: "#eb4b6a" }}>Campus representatives:</h5>
                <p>
                  Do you want to join our campus team to organise bootcamps in
                  your school while you get training, work experience and
                  mentorship? Apply now.
                </p>
                <a
                  href="https://drive.google.com/open?id=1vdHGGxvcVQnb26zDfgGQySHr8eU5_ZU4OWpZfWuL1ow"
                  className="btn-custom-secondary-small"
                >
                  Apply
                </a>
                <br /> <br />
                <h5 style={{ color: "#eb4b6a" }}>Volunteers:</h5>
                <p>
                  Do you want to volunteer in a meaningful cause that is helping
                  create wealth and jobs in Africa? Apply now.
                </p>
                <a
                  href="https://drive.google.com/open?id=1vdHGGxvcVQnb26zDfgGQySHr8eU5_ZU4OWpZfWuL1ow"
                  className="btn-custom-secondary-small"
                >
                  Apply
                </a>
              </div>
              <br />
              <Link to="/">Please send me to the home page</Link>
              <br />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default Careers
